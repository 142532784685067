<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { Icon } from "@iconify/vue2";
import Multiselect from "vue-multiselect";
import { statusEnum } from "@/components/enums.js";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Rezervasyonlar",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Icon, Multiselect },
  data() {
    return {
      isBusy: false,
      statusEnum,
      title: "Rezervasyonlar",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Rezervasyonlar",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      values: {
        status: {
          value: 1,
          text: "Rezervasyon Oluşturuldu.",
        },
        first_date: 0,
        last_date: 0,
        confirme: 0,
      },
      currentDate: "2024-01-01",
      lastCurrentDate: "2025-01-01",
      fields: [
        { key: "id", sortable: true, label: "#" },
        { key: "agency.company_name", sortable: true, label: "Acenta" },
        { key: "customer.customer_name", sortable: true, label: "Müşteri" },
        {
          key: "reservation_type.label",
          sortable: true,
          label: "Rezervasyon Türü",
        },
        { key: "status", sortable: true, label: "Rezervasyon Durumu" },
        { key: "updated_at", sortable: true, label: "Son Güncelleme" },
        { key: "actions", label: "İşlemler" },
      ],
    };
  },
  methods: {
    sorgula() {
      this.getData(this.currentPage);
      this.$refs.btable.refresh();
    },
    getData(ctx, callback) {
      const params = "?page=" + ctx.currentPage;
      const data = {
        status: this.values.status.value,
        first_date: this.values.first_date === 0 ? this.currentDate : this.values.first_date,
        last_date: this.values.last_date === 0 ? this.lastCurrentDate : this.values.last_date,
        confirme: this.values.confirme,
      }
      axios
        .post(process.env.VUE_APP_BASEURL + "/reservation/status" + params, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const items = response.data.data;
            this.totalRows = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            callback(items);
          }
        })
        .catch((error) => {
          callback([]);
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
      this.isBusy = false;
      return;
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    revenue(itemId) {
      const url = `/revenue/add?type=reservation&id=${itemId}`;
      window.open(url, '_blank');
    },
    expense(itemId) {
      const url = `/expense/add?type=reservation&id=${itemId}`;
      window.open(url, '_blank');
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="sorgula">
              <div class="row">
                <div class="col-3">
                  <div class="form-group mb-3">
                    <label for="brand_name">Giriş Tarihi</label>
                    <input v-model="values.first_date" type="date" class="form-control" id="first_date" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group mb-3">
                    <label for="brand_name">Çıkış Tarihi</label>
                    <input v-model="values.last_date" type="date" class="form-control" id="last_date" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group mb-3">
                    <label for="brand_name">Confirme</label>
                    <input v-model="values.confirme" type="text" class="form-control" id="confirme" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group mb-3">
                    <label for="brand_name">Rezervasyon Durumu</label>
                    <multiselect v-model="values.status" :options="statusEnum" @input="onChangeType" label="text">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Sorgula</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Rezervasyonlar</h4>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="getData" ref="btable" :fields="fields" :per-page="10" :total-rows="totalRows"
                responsive="sm" :current-page="currentPage" :busy.sync="isBusy">
                <template #table-busy>
                  <div class="d-flex flex-column align-items-center justify-content-center gap-1 my-2">
                    <Icon icon="lucide:loader" class="spinner-rotate" :style="{ fontSize: '72px' }" />
                  </div>
                </template>
                <template #cell(updated_at)="data">
                  {{ data.item.updated_at | moment("DD.MM.YYYY HH:mm") }}
                </template>
                <template #cell(status)="data">
                  <b-badge :variant="data.item.status.variant">
                    {{ data.item.status.label }}
                  </b-badge>
                </template>
                <template v-slot:cell(actions)="data">
                  <button @click="revenue(data.item.id)" class="btn btn-sm btn-success">
                    Gelir
                  </button>
                  <button @click="expense(data.item.id)" class="btn btn-sm btn-danger ml-2">
                    Gider
                  </button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                      @change="onPageChange"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>